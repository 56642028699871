//.header-link {
//    font-size: 18px;
//    color: #666666;
//
//    &:hover {
//        color: #666666;
//    }
//}
//
//.gallery {
//
//    #slider {
//        position: relative;
//        overflow: hidden;
//        margin: 20px auto 0 auto;
//        border-radius: 4px;
//
//        .images {
//            position: relative;
//            margin: 0;
//            padding: 0;
//            width: 100%;
//            height: 600px;
//            list-style: none;
//
//            .image {
//                background-size: cover;
//                background-position: center center;
//                position: relative;
//                display: block;
//                float: left;
//                margin: 0;
//                padding: 0;
//                width: 100%;
//                height: 600px;
//                text-align: center;
//                line-height: 300px;
//                cursor: -moz-zoom-in;
//                cursor: -webkit-zoom-in;
//                cursor: zoom-in;
//            }
//        }
//    }
//
//    a.control_prev, a.control_next {
//        position: absolute;
//        top: 40%;
//        z-index: 999;
//        display: block;
//        padding: 4% 3%;
//        width: auto;
//        height: auto;
//        color: #fff;
//        text-decoration: none;
//        font-weight: 600;
//        font-size: 18px;
//        opacity: 0.8;
//        cursor: pointer;
//    }
//
//    a.control_prev:hover, a.control_next:hover {
//        opacity: 1;
//        -webkit-transition: all 0.2s ease;
//    }
//
//    a.control_prev {
//        border-radius: 0 2px 2px 0;
//    }
//
//    a.control_next {
//        right: 0;
//        border-radius: 2px 0 0 2px;
//    }
//
//    .slider_option {
//        position: relative;
//        margin: 10px auto;
//        width: 160px;
//        font-size: 18px;
//    }
//}
//
//.info {
//    padding-bottom: 30px;
//    border-bottom: 2px solid #ebebeb;
//
//    .guitar-id {
//        float: right;
//        font-size: 24px;
//        color: white;
//        background-color: #666666;
//        padding: 5px 20px;
//        font-weight: 100;
//    }
//
//    .name {
//        font-size: 24px;
//        color: black;
//        line-height: 30px;
//        margin-right: 80px;
//    }
//
//    .year {
//        font-size: 24px;
//        color: #999999;
//        float: left;
//        margin-right: 15px;
//    }
//
//    .serial {
//        color: #666666;
//        padding-top: 10px;
//        font-size: 12px;
//    }
//
//    &.top-info {
//        display: none;
//    }
//
//    @media ( max-width: 768px) {
//        &.top-info {
//            display: block;
//        }
//
//        &.bottom-info {
//            display: none;
//        }
//    }
//
//}
//
//.shapes {
//    text-align: center;
//
//    .shape {
//        display: inline-block;
//        margin-top: 20px;
//        margin-right: 20px;
//
//        .shape-text {
//            font-size: 12px;
//            color: #666666;
//        }
//
//        .shape-image {
//            width: 45px;
//        }
//    }
//}
//
//.description {
//    margin-top: 35px;
//    font-size: 14px;
//    color: #666666;
//}
//
//.attributes {
//    margin-top: 50px;
//
//    .attribute {
//        margin-bottom: 5px;
//        font-size: 12px;
//
//        .key {
//            float: left;
//            min-width: 130px;
//        }
//
//        .value {
//            float: left;
//        }
//    }
//}
//
//.list-section {
//    margin-top: 30px;
//
//    .section-heading {
//        color: #999999;
//        font-size: 12px;
//        text-transform: uppercase;
//        margin-bottom: 5px;
//    }
//
//    .section-item {
//        color: #666666;
//        font-weight: bold;
//        margin-top: 0;
//        margin-bottom: 0;
//    }
//}

.guitar-detail {

    .container-fluid {
        padding-top: 50px;
    }

    .gallery {

        #slider {
            position: relative;
            overflow: hidden;
            margin: 0 auto 0 auto;
            border-radius: 4px;
            max-width: 600px;

            .images {
                position: relative;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 800px;
                list-style: none;

                .image {
                    //border: red thin solid;
                    background-size: cover;
                    background-position: bottom center;
                    position: relative;
                    display: block;
                    float: left;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    min-height: 800px;
                    text-align: center;
                    line-height: 300px;
                    cursor: -moz-zoom-in;
                    cursor: -webkit-zoom-in;
                    cursor: zoom-in;
                }

                //@media(min-width: 1400px) {
                //    & {
                //        border: green thick solid;
                //        min-height: 800px;
                //
                //        .image {
                //            min-height: 800px;
                //        }
                //    }
                //}



            }
        }

        a.control_prev, a.control_next {
            position: absolute;
            top: 40%;
            z-index: 999;
            display: block;
            padding: 4% 3%;
            width: auto;
            height: auto;
            color: #fff;
            text-decoration: none;
            font-weight: 600;
            font-size: 18px;
            opacity: 0.8;
            cursor: pointer;
        }

        a.control_prev:hover, a.control_next:hover {
            opacity: 1;
            -webkit-transition: all 0.2s ease;
        }

        a.control_prev {
            color: #666666;
            opacity: 0.5;
            border-radius: 0 2px 2px 0;
        }

        a.control_next {
            right: 0;
            color: #666666;
            opacity: 0.5;
            border-radius: 2px 0 0 2px;
        }

        .slider_option {
            position: relative;
            margin: 10px auto;
            width: 160px;
            font-size: 18px;
        }
    }

    .header {
        overflow: auto;
        padding-bottom: 30px;
        border-bottom: 2px solid #eaeaea;
        margin-bottom: 20px;

        &.-mobile {
            border-bottom: none;
            margin-bottom: 0px;
        }

        .id {
            float: right;
            text-align: center;
            width: 100px;
            padding: 10px;
            background-color: $dark-grey;
            font-size: 28px;
            font-weight: bold;
            color: #ffffff;
        }
    }

    .title {
        float: left;

        h1 {
            font-size: 28px;
            font-weight: bold;
        }

        h2 {
            display: inline-block;
            font-size: 28px;
            font-weight: bold;
            color: $light-grey;
        }
        .serial {
            display: inline-block;
            font-size: 14px;
            font-weight: normal;
            color: $light-grey;
        }
    }

    .attributes {

        font-size: 12px;
        font-weight: bold;
        color: $dark-grey;
        margin-top: 15px;
        margin-bottom: 30px;
        overflow: auto;
        text-align: center;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        .-three-columns {
            float: left;
            width: 33%;
        }

        .icon {
            opacity: 0.6;
            width: 45px;
            height: 45px;
            margin-bottom: 10px;
            display: block;
            margin-right: auto;
            margin-left: auto;
        }

        a {
            color: $dark-grey;
            text-decoration: none;
            border-bottom: 1px $dark-grey dotted;
        }

        .make {
            .icon {
                //width: 50%;
                //height: auto!important;
            }
        }

        .head {
            padding-top: 5px;

            .icon {
                width: 40px;
                height: 40px;
            }
        }
    }

    .description {
        font-size: 16px;
        font-weight: normal;
        font-style: italic;
        color: $dark-grey;
        margin-bottom: 50px;
    }

    .properties {
        margin-bottom: 50px;

        table {
            font-size: 12px;
            font-weight: bold;
            color: $dark-grey;

            .guitar-finish-icon {
                width: 20px;
                margin-right: 10px;
            }
        }
    }

    .accessories {
        margin-bottom: 50px;
    }

    .memos {
        margin-bottom: 50px;
    }

    h3 {
        font-size: 14px;
        font-weight: bold;
        color: $light-grey;
    }

    ul {
        list-style: none;
        padding-left: 0px;

        li {
            line-height: 30px;
            font-size: 12px;
            font-weight: bold;
            color: $dark-grey;
        }
    }

    @include media-breakpoint-up(md) {
        .hidden-md-up {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .hidden-sm-down {
            display: none;
        }
    }

}