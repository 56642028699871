$background-grey: #fafafa;
$dark-grey: #666666;
$light-grey: #9f9f9f;
$very-light-grey: #cccccc;

body {
    background-color: $background-grey;
    font-family: 'Open-Sans', sans-serif;
    letter-spacing: 1px;
}

.container-fluid {
    padding: 20px;
}

a:hover {
    text-decoration: none;
}

.top-bar {
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #eaeaea;
    position: fixed;
    z-index: 99999;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fafafa;
    opacity: 0.98;

    .logo {
        float: left;
        margin-right: 15px;

        img {
            width: 25px;
        }
    }

    .search-bar {
        display: block;
        width: 70%;
        font-size: 14px;
        letter-spacing: 1px;
        color: $light-grey;
        background-position: 9px 8px;
        background-image: url('/images/ui/icon-search.png');
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding-left: 40px;
        border: none;
    }

    .filter-icon {
        float: right;
        margin-top: 3px;
        width: 25px;
        height: 25px;
        background-image: url('/images/filter-icon.png');
        background-position: center;
        background-size: contain;
        display: none;
    }

    @media(max-width: 768px) {
        .search-bar {
            display: block;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            float: none;
        }

        .filter-icon {
            display: block;
            float: right;
        }
    }

    ::-webkit-input-placeholder {
        color: $very-light-grey;
    }

    ::-ms-input-placeholder {
        color: $very-light-grey;
    }

    ::-moz-placeholder {
        color: $very-light-grey;
        opacity: 1;
    }

    .button-filter {
        float: right;

        img {
            width: 30px;
            height: 30px;
        }
    }
}

.filters {
    margin-bottom: 50px;
    padding: 25px 10px 25px 25px;

    .filter {
        margin-bottom: 25px;
    }

    .filter-header {
        overflow: auto;
        h3 {
            margin-top: 2px;
        }

        .badge {
            margin-left: 3px;
        }
    }

    h3 {
        float: left;
        font-size: 14px;
        font-weight: bold;
        color: $light-grey;
        margin-bottom: 25px;
        padding-top: 5px;

        &.-active {
            color: $dark-grey;

            .badge {
                display: none;
                position: relative;
                top: -2px;
            }
        }
    }

    .reset-filters {
        margin-top: 2px;
        float: right;
        font-size: 10px;
        font-weight: bold;
        color: $light-grey;
        display: none;
        cursor: pointer;

        img {
            width: 10px;
            height: 10px;
            fill: $light-grey;
        }
    }

    .checklist {
        padding-left: 0;
        list-style-type: none;

        .checklist-item {
            padding-left: 0;
            text-indent: 0;
            font-size: 12px;
            font-weight: bold;
            color: $dark-grey;
            margin-bottom: 15px;
            cursor: pointer;

            .rectangle {
                display: inline-block;
                width: 16px;
                height: 16px;
                background-color: white;
                border: #ececec solid 2px;
                margin-bottom: -3px;
                margin-right: 5px;
            }

            &.-active {
                font-weight: bold;

                .rectangle {
                    background-color: #666666;
                    border: none;
                }
            }
        }
    }

    .iconlist {
        padding-left: 0;
        list-style-type: none;
        cursor: pointer;

        .iconlist-item {
            padding-left: 0;
            text-indent: 0;
            font-size: 12px;
            font-weight: bold;
            color: $dark-grey;
            margin-bottom: 15px;

            .-icon {
                opacity: 0.25;
                height: 45px;
                width: 45px;

                &.-guitar {
                    margin-left: -10px;
                    margin-right: 8px;
                }

                &.-head {
                    height: 35px;
                    width: 35px;
                    margin-left: -5px;
                    margin-right: 15px;
                }

                &.-pickup {
                    height: 16px;
                    width: 35px;
                    margin-right: 5px;
                }
            }

            &.-active {
                .-icon {
                    opacity: 0.5;
                }
            }
        }
    }

    .filter-count {
        font-weight: 400;
        font-size: 10px;
    }
}

//.guitar-list {
//    position: relative;
//
//    //@include media-breakpoint-up(xl) {
//    //    column-count: 4;
//    //}
//    //
//    //@include media-breakpoint-down(lg) {
//    //    column-count: 3;
//    //}
//    //
//    //@include media-breakpoint-down(md) {
//    //    column-count: 2;
//    //}
//    //
//    //@include media-breakpoint-down(sm) {
//    //    column-count: 1;
//    //}

    .card {
        //display: inline-block!important;
        margin-bottom: 30px;

        .card-body {
            min-height: 95px;

            .guitar-make {
                color: $dark-grey;
                font-size: 14px;
                font-weight: bold;
                margin: 0 0 5px 0;
            }

            .guitar-name {
                color: $dark-grey;
                font-size: 16px;
                font-weight: bold;
                margin: 0 0 0 0;
            }

        }
    }
//}

.footer {
    background-color: #ffffff;
}

#guitar-template > div{
    display: none!important;
}

.loading-overlay {
    display: none;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    background-color: #666666;
    background-image: url('../images/loader.gif');
    background-size: 60px 60px;
    background-position: center 150px;
    background-repeat: no-repeat;
}

#wrapper {
    padding-right: 0;
    margin-top: 60px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-right: 300px;
}

#sidebar-wrapper {
    z-index: 99998;
    position: fixed;
    overflow-y: scroll;
    right: 300px;
    width: 0;
    height: 100%;
    margin-right: -300px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 300px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-left: -300px;
}

/* Sidebar Styles */
.sidebar-nav {
    position: absolute;
    top: 0;
    width: 300px;
    margin: 0;
    padding: 0;
    list-style: none;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 0;
    }
    #wrapper.toggled {
        padding-right: 300px;
    }
    #sidebar-wrapper {
        width: 0;
    }
    #wrapper.toggled #sidebar-wrapper {
        width: 300px;
    }
    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }
    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-left: 0;
    }
}

.empty-list {
    font-size: 24px;
    text-align: center;
    color: $dark-grey;
    display: none;
    height: 100%;
}

.guitar-list {
    //display: none;
}

//.offset-loader {
//    display: block;
//    //display: none;
//    width: 30px;
//    margin-left: auto;
//    margin-right: auto;
//}

.offset-loader,
.offset-loader:before,
.offset-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}
.offset-loader {
    color: #666666;
    font-size: 10px;
    margin: 30px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.offset-loader:before,
.offset-loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.offset-loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.offset-loader:after {
    left: 3.5em;
}
@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

#scroll-top {
    position: fixed;
    bottom: 15px;
    right: 5px;
    font-size: 32px;
    cursor: pointer;
    display: none;
    color: #666666;
}