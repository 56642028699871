// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

body {
    font-family: 'Roboto', sans-serif;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 150px;
}

h2 {
    margin-top: 0;
}

.navbar-brand {
    font-size: 30px;
    font-weight: 100;
}

.label-sticker {
    border: grey thin solid;
    padding: 20px;
}

.sticker-name {
    font-size: 35px;
}

.sticker-url {
    font-size: 20px;
    margin-bottom: 30px;
}

.login-takara-sign {
    margin-top: 20px;
    font-size: 100px;
    font-weight: 100;
}

.login-heading {
    margin-top: 0;
    font-weight: 100;
    font-size: 70px;
}

.right-btn-margin {
    margin-left: 3px;
}

.guitar-name, .guitar-name:hover, .guitar-name:active {
    color: #636b6f!important;
}

.admin-link {
    position: absolute;
    top: 0;
    right: 2px;
}

.admin-link > a, .admin-link > a:hover, .admin-link > a:active, .admin-link > a:focus {
    font-size: 8px;
    color: #666666;
}

.login-logo {
    display: block;
    width: 70px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    margin-top: 30px;
}

.top-logo {
    display: block;
    width: 35px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
}

@import "_guitars";
@import "_guitar";

